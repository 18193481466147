import { WithRef } from "@converge-collective/common/models/Base";
import {
  Folder,
  FolderTreeNode,
} from "@converge-collective/common/models/Folder";
import { Network } from "@converge-collective/common/models/Network";
import {
  nanoProfile,
  Profile,
} from "@converge-collective/common/models/Profile";
import {
  collection,
  and,
  or,
  orderBy,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { minRead } from "./authz";
import { converters } from "~/lib/converter";
import { Doc } from "@converge-collective/common/models/Doc";
import {
  appendDocUpdate,
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";

/** Create a query while taking into account authz */
export const allNetworkFoldersQuery = (
  network: WithRef<Network>,
  profile: WithRef<Profile>,
  isNetworkAdmin: boolean
) => {
  // console.log("allNetworkFoldersQuery", { network, profile, isNetworkAdmin });
  //

  const whereActive = where(
    "latestDocUpdate.status",
    "!=",
    DocStatuses.Archived
  );

  const baseQuery = query(
    collection(network.ref, "folders"),
    whereActive,
    orderBy("name")
  );

  return (
    isNetworkAdmin
      ? baseQuery
      : query(
          baseQuery,
          and(
            or(
              // either the entire network has access
              where(
                "authzGrants.access.networkWide.accessLevel",
                "in",
                minRead
              ),
              // or the user specifically has access
              where(`authzGrants.userIds`, "array-contains", profile.id)
            )
          )
        )
  ).withConverter(converters.folder.read);
};

/**
 * When we archive a folder we mark it as "archived" in the database,
 * and also mark all docs within it as "archived". This is to preserve the
 * integrity of the data, and also to allow for easy recovery of the folder and
 * its docs if necessary while also taking into account authz.
 */
export const archiveFolder = async (
  profile: WithRef<Profile>,
  folder: WithRef<Folder>,
  subFolder?: FolderTreeNode
) => {
  const docUpdate: DocUpdate = {
    actor: nanoProfile(profile),
    status: DocStatuses.Archived,
    description: `Folder archived by ${profile.name}`,
    date: new Date(),
  };

  // TODO do we need to mark the subfolders as archived as well?

  if (subFolder) {
    const firestorePath = `children.${subFolder.path.join(".children.")}`;
    const newSubFolder: FolderTreeNode = appendDocUpdate(docUpdate, subFolder);
    console.log("archiving subfolder at path", { firestorePath, newSubFolder });
    await updateDoc(folder.ref, {
      [firestorePath]: newSubFolder,
    });
  } else {
    await updateDoc(folder.ref, appendDocUpdate(docUpdate, folder));
  }

  // TODO archive all the docs in the folder or subfolder?

  // const docsQuery = allDocsInFolderQuery(
  //   network,
  //   folder,
  //   profile,
  //   isNetworkAdmin
  // );

  // const docsToDelete = modelsFromQuerySnap<Doc>(await getDocs(docsQuery));
  // console.log("delete docs", { docsToDelete });
  // const date = new Date();
  // // mark all the docs as "deleted"
  // await Promise.all(
  //   docsToDelete.map(async (doc) => {
  //     const docUpdateLog: DocUpdate = {
  //       date,
  //       status: DocStatuses.Deleted,
  //       description: `Doc "${doc.name}" deleted by ${profile.name}`,
  //       actor: liteProfile(profile),
  //       entity: doc.ref,
  //     };
  //     await updateDoc(doc.ref, appendDocUpdate(docUpdateLog, doc));
  //   })
  // );
};

export const archiveDocs = async (
  profile: WithRef<Profile>,
  docs: WithRef<Doc>[]
) => {
  // mark all the docs as archived
  await Promise.all(
    docs.map(async (doc) => {
      const docUpdateLog: DocUpdate = {
        date: new Date(),
        status: DocStatuses.Archived,
        description: `Doc "${doc.name}" deleted by ${profile.name}`,
        actor: nanoProfile(profile),
      };
      await updateDoc(doc.ref, appendDocUpdate(docUpdateLog, doc));
    })
  );
};

/**
 * Data structure that makes it very easy for UI to work with hierarchical
 * folders and subfolders.
 * can represent a root parent node or a subfolder node */
export type FolderNodeInfo = {
  root: Folder;
  /** the slug of the root or subfolder */
  slug: string;
  subFolderInfo?: {
    subFolder: FolderTreeNode;
    /** the full set of FolderTreeNodes from root to subFolder (inclusive) */
    fullPath: FolderTreeNode[];
  };
};

/**
 * Given an array of top level folders, return a flattened array of all folders
 * and all their nested subfolders as FolderNodeInfo
 * e.g. simplified representation (see FolderNodeInfo for full representation):
 * [
 *   ["Docs"],
 *   ["A", "B"]
 *   ["A", "B", "C"]
 *   ["Parent, "Child"]
 * ]
 */
export const flattenFolders = (folders: Folder[]): FolderNodeInfo[] =>
  folders.flatMap((folder) => [
    {
      root: folder,
      slug: folder.slug,
    },
    ...flattenSubFolders(folder, [folder], Object.values(folder.children)),
  ]);

/**
 * Determine the slug for a FolderNodeInfo. If it's a subfolder, return the
 * slug of the subfolder. If it's the root, return the slug of the root.
 */
export const folderInfoSlug = (folderInfo: FolderNodeInfo) =>
  folderInfo.subFolderInfo
    ? folderInfo.subFolderInfo.subFolder.slug
    : folderInfo.root.slug;

export const subfolderOrRoot = (folderInfo: FolderNodeInfo): FolderTreeNode =>
  folderInfo.subFolderInfo
    ? folderInfo.subFolderInfo.subFolder
    : folderInfo.root;

/** recursively expand subfolders of a subfolder */
export const flattenSubFolders = (
  root: Folder,
  parents: FolderTreeNode[],
  subFolders: FolderTreeNode[]
): FolderNodeInfo[] => {
  // console.log("flatten subfolders", { root, parents, subFolders });
  return subFolders.flatMap((subFolder) => [
    {
      root,
      slug: subFolder.slug,
      subFolderInfo: {
        subFolder,
        fullPath: parents,
      },
    },
    ...flattenSubFolders(
      root,
      [...parents, subFolder],
      Object.values(subFolder.children)
    ),
  ]);
};
