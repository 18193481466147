import { deferred } from "@converge-collective/common/util";
import {
  PhotoCamera as PhotoCameraIcon,
  PhotoSizeSelectLarge,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  ImageList,
  ImageListItem,
  Slider,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { ReactElement, useRef, useState } from "react";
import AvatarEditor, { AvatarEditorProps } from "react-avatar-editor";
import { useStorage, useUser } from "reactfire";
import { createAlert } from "~/lib/globalAlerts";

export const baseUploadPath = "images/uploads";

export default function Uploader({
  onPhotosSelected,
  onPhotosUploadStart,
  onPhotosUploaded,
  onResetPhotos,
  uploadPath = baseUploadPath,
  uploadButtonText = "Add Photos",
  allowMultipleFiles = true,
  allowedFileTypes = ["image/png", "image/jpeg"],
  sx = { mt: 2 },
  allowCrop = false,
  avatarEditorProps,
  onReadyToUpload,
  // parent can optionally pass in the files to upload. This is useful when
  // the parent wants its own UI for file collection.
  initFiles,
  // the parent can specify that the upload button should be rendered
  showSaveCropButton = false,
  uploadButton,
  onInputBlur,
}: {
  onPhotosUploaded: ({ photoUrls }: { photoUrls: string[] }) => void;
  onPhotosSelected?: () => void;
  onResetPhotos?: () => void;
  onPhotosUploadStart?: () => void;
  // allows the parent to start the upload process when allowCrop is enabled and
  // we can't immediately upload the file after it's selected by the user.
  onReadyToUpload?: (a: () => Promise<string[] | undefined>) => void;
  uploadPath?: string;
  uploadButtonText?: string;
  allowMultipleFiles?: boolean;
  allowedFileTypes?: string[];
  allowCrop?: boolean;
  avatarEditorProps?: Partial<AvatarEditorProps>;
  initFiles?: File[];
  sx?: SxProps;
  showSaveCropButton?: boolean;
  uploadButton?: (children: ReactElement) => ReactElement;
  onInputBlur?: () => void;
}): React.ReactElement {
  const storage = useStorage();
  const { data: user } = useUser();
  const [files, setFiles] = useState<File[] | undefined>(initFiles);
  const [filesProgress, setFilesProgress] = useState<Record<number, number>>(
    {}
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const refsMap = useRef<Map<number, AvatarEditor>>(new Map());

  // NOTE: we pass files around because of how this function is called by a
  // parent using a thunk that closes over current state. We could clean up all
  // this mess if this gets fixed:
  // https://github.com/mosch/react-avatar-editor/issues/396
  const getFilesFromCanvases = async (files: File[]): Promise<Array<File>> => {
    console.log("getFilesFromCanvases", { files });
    const scaledImages = await Promise.all(
      (files || []).map((file, fileIndex) => {
        const ref = refsMap.current.get(fileIndex);
        if (ref) {
          // only use the scaled image if crop is enabled. otherwise use the
          // original image
          const scaled = allowCrop
            ? ref.getImageScaledToCanvas()
            : ref.getImage();
          const promise = new Promise<File>((resolve, reject) => {
            scaled.toBlob(
              (blob) => {
                if (blob) {
                  const scaledFile = new File([blob], file.name, {
                    type: file.type,
                  });
                  resolve(scaledFile);
                } else {
                  console.error("Failed to scale image", { file, blob });
                  reject("Failed to scale image");
                }
              },
              file.type,
              9
            );
          });
          return promise;
        } else {
          console.warn("unexpected missing ref", {
            ref,
            fileIndex,
            file,
            refsMap: refsMap.current,
          });
          return undefined;
        }
      })
    );
    return scaledImages.filter((i): i is File => !!i);
  };

  /**
   * Returns a promise that resolves to photo urls (if successful)
   */
  const uploadFiles = async (files: File[]): Promise<string[] | undefined> => {
    const filesToUpload = allowCrop ? await getFilesFromCanvases(files) : files;
    if (!user) {
      console.error("user is not logged in");
      return;
    }
    if (filesToUpload) {
      console.log("upload files!", { filesToUpload });
      onPhotosUploadStart?.();
      setIsUploading(true);
      const uploadPromises = filesToUpload.map(async (file, fileIndex) => {
        const { promise, resolve, reject } = deferred<UploadTaskSnapshot>();

        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          // const binaryStr = reader.result;
          const time = new Date().getTime();
          const path = `${uploadPath}/${user.uid}/${time}.${file.name}`;
          const imageDir = ref(storage, path);
          const task = uploadBytesResumable(imageDir, file);
          task.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.ceil(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              // console.log(progress);
              setFilesProgress((prev) => ({
                ...prev,
                [fileIndex]: progress,
              }));
              fileIndex;
            },
            (error) => reject(`error uploading file ${error}`),
            () => resolve(task.snapshot)
          );
        };
        reader.readAsArrayBuffer(file);
        return promise;
      });
      const uploads = await Promise.all(uploadPromises);
      const photoUrls: string[] = await Promise.all(
        uploads.map((u) => getDownloadURL(u.ref))
      );
      console.log("Uploader photos complete", photoUrls);
      onPhotosUploaded?.({ photoUrls });
      setIsUploading(false);
      // clear dialog
      setFiles(undefined);
      return photoUrls;
    } else {
      console.warn("no files to upload", { files });
      return;
    }
  };

  // if the user passes in initFiles then the upload is immediately ready, so
  // fire `onReadyToUpload` immediately
  useState(() => {
    if (initFiles) {
      console.log("fire onReadyToUpload", { initFiles });
      onReadyToUpload?.(() => uploadFiles(initFiles));
    }
  });

  const handlePhotosSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const imageFile = e.target.files[0];
      const allowfile = allowedFileTypes.includes(imageFile.type);

      if (!allowfile) {
        createAlert({
          message: "Please select valid file",
          severity: "error",
          title: "invalid file",
        });

        return;
      }
      const files = Array.from(e.target.files);
      setFiles(files);
      onPhotosSelected?.();
      // allow the parent to trigger the upload when the user hits "save" or
      // whatever

      // NOTE: this is sorta gross but we have to pass files into uploadFiles
      // because of how this function is closing over the current state of the
      // React component
      onReadyToUpload?.(() => uploadFiles(files));

      // if crop is not enabled start uploading the photos immediately
      if (!allowCrop) {
        uploadFiles(files);
      }
    } else {
      console.warn("no files selected");
    }
  };

  const [zoomByIndex, setZoomByIndex] = useState<Record<number, number>>({});
  // const [refsByIndex, setRefsByIndex] = useState<Record<number, React.RefObject<AvatarEditor>>>({});
  // const previewMarginY =
  //   scalePreview === 1 ? 0 : `-${(1 - scalePreview) * 2 * 100}%`;
  // console.log("Uploader previewMarginY", { previewMarginY });

  return (
    <>
      {initFiles ? (
        <></>
      ) : (
        <Stack direction="row" justifyContent="center">
          {uploadButton ? (
            uploadButton(
              <input
                hidden
                onChange={handlePhotosSelected}
                accept={allowedFileTypes.join(",")}
                multiple={allowMultipleFiles}
                type="file"
              />
            )
          ) : (
            <Button
              startIcon={<PhotoCameraIcon />}
              size="large"
              variant="contained"
              component="label"
            >
              {uploadButtonText}
              <input
                hidden
                onChange={handlePhotosSelected}
                accept={allowedFileTypes.join(",")}
                multiple={allowMultipleFiles}
                type="file"
                onBlur={onInputBlur}
              />
            </Button>
          )}
        </Stack>
      )}

      {files && files.length > 0 && (
        <>
          {allowCrop ? (
            <Dialog
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  uploadFiles(files);
                }
                if (e.key === "Escape") {
                  setFiles(undefined);
                }
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              fullScreen={fullScreen}
              maxWidth={false}
              slotProps={{
                backdrop: {
                  style: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                },
              }}
              open={true}
            >
              <DialogContent sx={{}}>
                {files.map((file, fileIndex) => (
                  <Box key={fileIndex}>
                    <div
                      key={fileIndex}
                      style={{
                        // marginTop: previewMarginY,
                        // marginBottom: previewMarginY,
                        // marginLeft: previewMarginY,
                        // marginRight: previewMarginY,
                        // transform: `scale(${scalePreview})`,
                        // transformOrigin: "top left",

                        width: "100%",
                        display: "grid",
                        textAlign: "center",
                      }}
                    >
                      {/*
                      // @ts-ignore Upgrade next and react to see if this goes away */}
                      <AvatarEditor
                        style={{
                          width: "100%",
                          maxWidth: "100vh",
                          maxHeight: "100vh",
                          height: "auto",
                          margin: "auto",
                        }}
                        ref={(node) => {
                          if (node) {
                            refsMap.current.set(fileIndex, node);
                          } else {
                            refsMap.current.delete(fileIndex);
                          }
                        }}
                        onImageChange={() => {
                          // tell the parent to reset photos every time it's changed
                          // since it'll need to be re-uploaded
                          onResetPhotos?.();
                          // console.log("onImageChange", {
                          //   ref: refsByIndex[fileIndex],
                          // });
                          // const newFiles = [...files];
                          // newFiles[fileIndex] = image;
                          // setFiles(newFiles);
                        }}
                        key={fileIndex}
                        image={file}
                        width={120}
                        height={120}
                        border={20}
                        scale={zoomByIndex[fileIndex] || 1}
                        {...avatarEditorProps}
                      />
                    </div>
                    <Stack
                      direction="row"
                      sx={{ my: 1 }}
                      spacing={2}
                      justifyContent="center"
                    >
                      <Slider
                        color="light"
                        onChange={(_, value) => {
                          setZoomByIndex((prev) => ({
                            ...prev,
                            [fileIndex]: Array.isArray(value)
                              ? value[0]
                              : value,
                          }));
                        }}
                        min={0.5}
                        max={4}
                        step={0.2}
                        sx={{ width: { lg: 300, xs: 100 } }}
                        value={zoomByIndex[fileIndex] || 1}
                        defaultValue={1}
                        valueLabelDisplay="auto"
                      />
                    </Stack>
                  </Box>
                ))}
              </DialogContent>
              <DialogActions sx={{ borderTop: 0 }}>
                {showSaveCropButton && (
                  <>
                    <Button
                      onClick={() => {
                        setFiles(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      color="light"
                      variant="contained"
                      loading={isUploading}
                      startIcon={<PhotoSizeSelectLarge />}
                      onClick={() => {
                        uploadFiles(files);
                      }}
                    >
                      Upload {files.length > 1 ? "All" : ""}
                    </LoadingButton>
                  </>
                )}
              </DialogActions>
            </Dialog>
          ) : (
            <Stack sx={sx} direction="row" justifyContent="center">
              <ImageList
                sx={{ width: "100%", overflow: "hidden" }}
                rowHeight={avatarEditorProps?.height || 120}
                gap={2}
                cols={Math.min(6, files.length)}
              >
                {files.map((file, fileIndex) => (
                  <ImageListItem
                    cols={1}
                    rows={1}
                    key={file.name}
                    sx={{ position: "relative" }}
                  >
                    <div>
                      <img
                        style={{ objectFit: "cover" }}
                        width="100%"
                        height="100%"
                        key={file.name}
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          top: 0,
                          left: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(0,0,0,0.3)",
                        }}
                      >
                        <CircularProgress
                          style={{ width: 60, height: 60 }}
                          color="secondary"
                          variant="determinate"
                          value={filesProgress[fileIndex] || 0}
                        />
                      </div>
                    </div>
                  </ImageListItem>
                ))}
              </ImageList>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
