import {
  AccessLevel,
  AccessLevels,
  AuthzGrants,
} from "@converge-collective/common/models/Authz";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { isNetworkAdmin } from "@converge-collective/common/models/NetworkAdmin";
import { Profile } from "@converge-collective/common/models/Profile";
import { useLoggedInState } from "~/lib/useLoggedInState";

export const userAccessLevel = (
  authzGrants: AuthzGrants,
  isNetworkAdmin: boolean,
  profile: WithRef<Profile> | null | undefined
): AccessLevel => {
  // First check if the use is a network admin
  if (isNetworkAdmin) return AccessLevels.Admin;

  // NOTE: should user-specific settings superceed network-wide settings?
  // check any user specific permissions
  if (profile && authzGrants.userAccessLevels?.hasOwnProperty(profile.id)) {
    return authzGrants.userAccessLevels[profile.id].accessLevel;
  }

  // Provide default access of network
  return authzGrants.access.networkWide.accessLevel;
};

/**
 * Given an authz grant and a profile, determine the access level the user has
 * on the resource
 */
export const useUserAccessLevel = (
  /** the authzGrants on the entity to check user access level on */
  authzGrants: AuthzGrants,
  /**
   * the profile to check access level for (doesn't have to be the current user)
   */
  profile: WithRef<Profile> | null | undefined
): AccessLevel => {
  const { isNetworkAdmin } = useLoggedInState();
  return userAccessLevel(authzGrants, Boolean(isNetworkAdmin), profile);
};
