import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { algoliasearch } from "algoliasearch";

export const algoliaConfig = () => ({
  appId: process?.env?.ALGOLIA_APP_ID || "",
  apiKey: process?.env?.ALGOLIA_PUBLIC_API_KEY || "",
});

export const prefixedIndex = (index: string) =>
  `${process?.env?.ALGOLIA_PREFIX || ""}_${index}`;

type AlgoliaConfig = {
  appId: string;
  apiKey: string;
};

export const algoliaSearchClient = ({ appId, apiKey }: AlgoliaConfig) =>
  algoliasearch(appId, apiKey);

export const algoliaNetworkFilter = (network: WithRef<Network>) =>
  `network.id:${network.id}`;
