import { Tag } from "@converge-collective/common/models/Tag";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/material";
import ColoredChip from "./ColoredChip";
import { useIsMobile } from "~/hooks/mobile";
import { useRouter } from "next/router";
import { useLoggedInState } from "~/lib/useLoggedInState";

export function TagChip({
  tag,
  sx,
  onClick,
  onDelete,
}: {
  tag: Tag;
  sx?: SxProps;
  onClick?: () => void;
  onDelete?: () => void;
}): React.ReactElement {
  const isMobile = useIsMobile();
  const router = useRouter();
  const { network } = useLoggedInState();
  const CHIP_MAX_WIDTH = isMobile ? 120 : 150;
  const CHIP_ICON_WIDTH = 30;

  const EllipsisText = ({ children }: { children: React.ReactNode }) => (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
      }}
    >
      {children}
    </div>
  );
  return (
    <ColoredChip
      onClick={() => onClick && onClick()}
      sx={{
        ...sx,
        // disable click behavior until authz / tags filtering is supported
        // cursor: "pointer",
        // "&:hover": {
        //   backgroundColor: darken(tag.color, 0.4),
        // },
      }}
      size="small"
      bgcolor={tag.color}
      label={<EllipsisText>{tag.name}</EllipsisText>}
      onDelete={onDelete}
      deleteIcon={
        <CloseIcon
          style={{
            color: "white",
          }}
        />
      }
    />
  );
}
