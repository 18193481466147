import { converters } from "~/lib/converter";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { liteProfile } from "@converge-collective/common/models/Profile";
import { Tag } from "@converge-collective/common/models/Tag";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  SxProps,
  TextField,
  createFilterOptions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { collection, doc, setDoc } from "firebase/firestore";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useFirestoreCollectionData, useUser } from "reactfire";
import useProfile from "~/hooks/useProfile";
import { backgroundColors } from "~/lib/color";
import ColoredChip from "./ColoredChip";
import { TagChip } from "./TagChip";

type TagOptionType = Tag & { inputValue?: string; create?: boolean };

const DefaultTagColor = backgroundColors[0];

const filter = createFilterOptions<WithRef<TagOptionType>>();

function isArrayOfTags(value: unknown): value is WithRef<Tag>[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item !== "string")
  );
}

/**
 * This is a special tag that's lazily created when someone marks a comment as
 * a Q&A on a network that doesn't have a Q&A tag yet.
 */
const QATag: Partial<Tag> = {
  name: "Q&A",
  slug: "q-a",
  description: "Questions and Answers",
};

export default function AutocompleteTag({
  network,
  onChange,
  sx,
  value: valueProp,
  isQAndA = false,
  queryFilterTags,
  handleBlur,
  allowCreateNewTag = true,
}: {
  network: WithRef<Network>;
  onChange: (tags: WithRef<Tag>[]) => void;
  sx?: SxProps;
  value?: WithRef<Tag>[] | string[];
  isQAndA?: boolean;
  queryFilterTags?: WithRef<TagOptionType>[];
  handleBlur?: () => void;
  allowCreateNewTag?: boolean;
}): React.ReactElement {
  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = useState<WithRef<TagOptionType>[]>([]);

  const { status, data: networkTags = [] } = useFirestoreCollectionData<
    WithRef<TagOptionType>
  >(collection(network.ref, "tags").withConverter(converters.tag.read));

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const [newTagName, setNewTagName] = useState("");
  const [newTagDescription, setNewTagDescription] = useState("");
  const [newTagColor, setNewTagColor] = useState(DefaultTagColor);
  const [isCreatingTag, setIsCreatingTag] = useState(false);

  useEffect(() => {
    if (!valueProp) {
      return;
    }

    if (isArrayOfTags(valueProp)) {
      setValue(valueProp);
      return;
    }

    const tagsSelected: WithRef<TagOptionType>[] = [];
    valueProp.forEach((tag) => {
      const existingTag = networkTags.find((t) => t.id === tag);
      if (existingTag) {
        tagsSelected.push(existingTag);
      }
    });

    setValue(tagsSelected);
  }, [valueProp]);

  const resetTagForm = () => {
    setNewTagName("");
    setNewTagDescription("");
    setNewTagColor(DefaultTagColor);
  };

  const handleCreateTag = async (tagOverrides?: Partial<Tag>) => {
    if (!profile) {
      console.error("No profile");
      return;
    }

    setIsCreatingTag(true);
    const slug = tagOverrides?.slug
      ? tagOverrides?.slug
      : kebabCase(newTagName);

    const newTag = tagOverrides
      ? (tagOverrides as Omit<Tag, "createdBy" | "color">)
      : {
          slug,
          name: newTagName,
          description: newTagDescription,
        };

    try {
      const ref = doc(network.ref, "tags", slug);
      const tag: WithRef<Tag> = {
        id: ref.id,
        ref,
        color: newTagColor,
        createdBy: liteProfile(profile),
        ...newTag,
      };
      await setDoc(ref, tag);
      setValue([...value, tag]);
      onChange([...value, tag]);
      resetTagForm();
      setIsCreateDialogOpen(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsCreatingTag(false);
    }
  };

  useEffect(() => {
    const addQaTag = async (QATag: Partial<Tag>) => {
      if (profile) {
        const existingQaTag = networkTags.filter(
          (tag) => tag?.id === QATag?.slug
        );
        if (existingQaTag.length > 0) {
          setValue(existingQaTag);
        } else {
          handleCreateTag(QATag);
        }
      }
    };
    // If this is a Q&A, immediately create the the Q&A tag if it doesn't exist
    // on this network
    if (isQAndA) {
      addQaTag(QATag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQAndA, networkTags, profile]);

  const handleDelete = (tag: WithRef<TagOptionType>) => {
    const tagsFilter = value.filter((t) => t.id !== tag.id);
    setValue(tagsFilter);
    onChange(tagsFilter);
  };

  useEffect(() => {
    if (queryFilterTags?.length) {
      setValue(queryFilterTags);
    }
  }, [queryFilterTags]);

  return status === "loading" ? (
    <></>
  ) : (
    <>
      <Autocomplete
        sx={sx}
        multiple={true}
        freeSolo={true}
        value={value}
        onBlur={handleBlur}
        options={networkTags}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        onChange={(event, newValue, reason, details) => {
          console.log("onChange", { event, newValue, reason, details });
          if (details?.option.create && details?.option.inputValue) {
            setNewTagName(details.option.inputValue);
            setIsCreateDialogOpen(true);
          } else if (isArrayOfTags(newValue)) {
            setValue(newValue);
            onChange(newValue);
          }
        }}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name
          );

          const id = doc(collection(network.ref, "tags")).id;
          const ref = doc(network.ref, "tags", id);
          if (
            allowCreateNewTag &&
            profile &&
            inputValue !== "" &&
            !isExisting
          ) {
            filtered.push({
              id,
              ref,
              create: true,
              inputValue,
              name: `Add "${inputValue}"`,
              description: "",
              color: DefaultTagColor,
              slug: kebabCase(inputValue),
              createdBy: liteProfile(profile),
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Tags" placeholder="Tags" />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <TagChip tag={option} sx={{ my: 1 }} />
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <TagChip
              sx={{ mr: 1 }}
              tag={option}
              {...getTagProps({ index })}
              key={index}
              onDelete={() => handleDelete(option)}
            />
          ))
        }
      />
      <Dialog
        open={isCreateDialogOpen}
        maxWidth="sm"
        fullScreen={fullScreen}
        fullWidth
        onClose={() => setIsCreateDialogOpen(false)}
      >
        <DialogTitle>Create Tag</DialogTitle>
        <DialogContent>
          <TextField
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
            autoFocus
            margin="dense"
            label="Tag Name"
            fullWidth
          />
          <TextField
            value={newTagDescription}
            onChange={(e) => setNewTagDescription(e.target.value)}
            margin="dense"
            label="Tag Description"
            fullWidth
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Tag Color</InputLabel>
            <Stack
              sx={{ mt: 8, mb: 2 }}
              direction="row"
              justifyContent="center"
            >
              <CirclePicker
                colors={backgroundColors}
                color={newTagColor}
                onChange={(color) => setNewTagColor(color.hex)}
              />
            </Stack>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Tag Preview</InputLabel>
            <Stack mt={6} direction="row" justifyContent="center">
              <Box>
                <ColoredChip
                  size="medium"
                  bgcolor={newTagColor}
                  label={newTagName}
                />
              </Box>
            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreateDialogOpen(false)}>Cancel</Button>
          <LoadingButton
            onClick={() => handleCreateTag()}
            loading={isCreatingTag}
            variant="contained"
          >
            Create Tag
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
