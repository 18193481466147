import { formatDateSlug } from "@converge-collective/common/util";
import { kebabCase } from "lodash";

export const slugify = (str: string, date?: Date) => {
  // create a unique slug from a name by using kebabCase and appending a number
  // representing the date and time

  if (!date) {
    return kebabCase(str);
  }
  return `${kebabCase(str)}-${formatDateSlug(date)}`;
};
