import {
  AccessLevel,
  AccessLevels,
} from "@converge-collective/common/models/Authz";
import { Values } from "@converge-collective/common/util";

/**
 * Authz helpers
 */
export const minRead: AccessLevel[] = [
  AccessLevels.Read,
  AccessLevels.Write,
  AccessLevels.Admin,
] as const;
export const minWrite: AccessLevel[] = [
  AccessLevels.Write,
  AccessLevels.Admin,
] as const;
export const minAdmin: AccessLevel[] = [AccessLevels.Admin] as const;

export const hasMinRead = (accessLevel: AccessLevel) =>
  minRead.includes(accessLevel);

export const hasMinWrite = (accessLevel: AccessLevel) =>
  minWrite.includes(accessLevel);

export const hasMinAdmin = (accessLevel: AccessLevel) =>
  minAdmin.includes(accessLevel);

export type MinAccessLevelFn =
  | typeof hasMinRead
  | typeof hasMinWrite
  | typeof hasMinAdmin;

/**
 * Maintain a list of all authz-controlled actions across the app, and map them
 * to the minimum access level required to perform an action
 */
export const AuthorizedActionTypes = {
  DocsCreate: "DocsCreate",
  DocsEdit: "DocsEdit",
  DocsDelete: "DocsDelete",
  DocsEditAuthz: "DocsEditAuthz",
  DocsPostToFeed: "DocsPostToFeed",
  DocsPostToGroup: "DocsPostToGroup",
  DocsDraggable: "DocsDraggable",
  FoldersEdit: "FoldersEdit",
  FoldersDelete: "FoldersDelete",
} as const;

export type AuthorizedActionType = Values<typeof AuthorizedActionTypes>;

export const AuthorizedActions: Record<AuthorizedActionType, MinAccessLevelFn> =
  {
    [AuthorizedActionTypes.DocsCreate]: hasMinWrite,
    [AuthorizedActionTypes.DocsEdit]: hasMinWrite,
    [AuthorizedActionTypes.DocsDelete]: hasMinAdmin,
    [AuthorizedActionTypes.DocsEditAuthz]: hasMinAdmin,
    [AuthorizedActionTypes.DocsPostToFeed]: hasMinAdmin,
    [AuthorizedActionTypes.DocsPostToGroup]: hasMinRead,
    [AuthorizedActionTypes.FoldersEdit]: hasMinWrite,
    [AuthorizedActionTypes.FoldersDelete]: hasMinAdmin,
    [AuthorizedActionTypes.DocsDraggable]: hasMinWrite,
  };

export const canPerformAction = (
  action: AuthorizedActionType,
  accessLevel: AccessLevel
) => AuthorizedActions[action](accessLevel);
