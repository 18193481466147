import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { VerifiedUser } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { first } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { userAccessLevel } from "~/hooks/useAuthz";
import { useFolders } from "~/hooks/useFolders";
import { MinAccessLevelFn } from "~/lib/authz";
import { flattenFolders, FolderNodeInfo } from "~/lib/folder";
import {
  HydratedLoggedInState,
  withLoggedInState,
} from "~/lib/withLoggedInState";

function AutocompleteFolderWithState({
  /** Profile is required so that we can take into account authz */
  profile,
  network,
  isNetworkAdmin,
  onFolderSelected,
  sx,
  initSelectedSlug,
  textFieldProps,
  autoSelectFirst = false,
  authzFilter,
  removeIds,
}: HydratedLoggedInState & {
  onFolderSelected: (folder: FolderNodeInfo | null) => void;
  sx?: SxProps;
  initSelectedSlug?: string;
  textFieldProps?: TextFieldProps;
  autoSelectFirst?: boolean;
  removeIds?: string[];

  /**
   * Optional authz filter to filter out folders a user may not have access to
   * perform an action on */
  authzFilter?: {
    /** min access level required to see the folder */
    hasMinAccessLevel: MinAccessLevelFn;
  };
}): React.ReactElement {
  // const folders = useFolders({ profile, network });
  const { folders } = useFolders({ profile, network });
  const [selected, setSelected] = useState<FolderNodeInfo | null>(null);

  // memo this since it's potentially expensive to compute
  const flattenedFolders = useMemo(() => {
    const flatFolders = flattenFolders(folders);
    console.log("recomputing flattened folders", { folders, flatFolders });

    // Filter out folders that match removeIds
    const filteredFolders = flatFolders.filter(
      (f) => !(removeIds || []).includes(f.slug)
    );

    // Filter out active folders only
    return filteredFolders.filter((f) =>
      f.subFolderInfo && f.subFolderInfo.subFolder.latestDocUpdate
        ? f.subFolderInfo.subFolder.latestDocUpdate.status !==
          DocStatuses.Archived
        : !!f
    );
  }, [folders, removeIds]);

  // validate selected folder
  useEffect(() => {
    if (
      selected &&
      flattenedFolders.findIndex((f) => f.slug === selected.slug) === -1
    ) {
      setSelected(null);
    }
  }, [flattenedFolders]);

  // compute readonly folders so we can disable them
  const readOnlyFolders = authzFilter
    ? flattenedFolders.filter(
        (f) =>
          !authzFilter.hasMinAccessLevel(
            userAccessLevel(f.root.authzGrants, isNetworkAdmin, profile)
          )
      )
    : [];
  const readOnlyFolderIds = readOnlyFolders.map((f) =>
    f.subFolderInfo ? f.subFolderInfo.subFolder.slug : f.root.slug
  );

  useEffect(() => {
    if (initSelectedSlug) {
      const folder = flattenedFolders.find(
        (f) =>
          initSelectedSlug ===
          (f.subFolderInfo ? f.subFolderInfo.subFolder.slug : f.root.slug)
      );
      const hasWriteAccess = !readOnlyFolderIds.includes(initSelectedSlug);
      console.log({ hasWriteAccess, readOnlyFolderIds });

      if (folder) {
        if (hasWriteAccess) {
          setSelected(folder);
          onFolderSelected(folder);
        } else {
          setSelected(null);
          onFolderSelected(null);
        }
      }
    } else {
      // auto select first
      if (autoSelectFirst) {
        const folder = first(
          flattenedFolders.filter(
            (f) => !readOnlyFolderIds.includes(f.root.slug)
          )
        );
        if (folder) {
          setSelected(folder);
          onFolderSelected(folder);
        }
      }
    }
    // disable exhaustive-deps to prevent infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, initSelectedSlug]);

  // recursively expand folders
  // const expandFolder = (folder: FolderTreeNode) => {};

  const getOptionLabel = (folderNodeInfo: FolderNodeInfo) =>
    folderNodeInfo.subFolderInfo
      ? `${folderNodeInfo.subFolderInfo.fullPath.map((n) => n.name).join(" / ")} / ${folderNodeInfo.subFolderInfo.subFolder.name}`
      : folderNodeInfo.root.name;

  const labelFromFolderNodeInfo = (folderInfo: FolderNodeInfo) => {
    return (
      <Stack direction="row">
        {folderInfo.subFolderInfo ? (
          <>
            <Typography variant="body1" color="grey.400">
              {folderInfo.subFolderInfo.fullPath.map((n) => n.name).join(" / ")}
              {" / "}
            </Typography>
            <Typography variant="body1">
              &nbsp;
              {folderInfo.subFolderInfo.subFolder.name}
            </Typography>
          </>
        ) : (
          <Typography variant="body1">{folderInfo.root.name}</Typography>
        )}
      </Stack>
    );
  };

  return (
    <Autocomplete
      sx={sx}
      value={selected}
      options={flattenedFolders}
      getOptionDisabled={(folderNodeInfo) =>
        readOnlyFolderIds.includes(folderNodeInfo.root.slug)
      }
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Folder"
          error={textFieldProps?.error}
          helperText={textFieldProps?.helperText}
        />
      )}
      getOptionLabel={getOptionLabel}
      renderOption={(props, folderNodeInfo) => {
        return (
          <li {...props}>
            {labelFromFolderNodeInfo(folderNodeInfo)}
            {readOnlyFolderIds.includes(folderNodeInfo.root.slug) && (
              <Chip
                sx={{ ml: 2 }}
                size="small"
                icon={<VerifiedUser />}
                label="Read Only"
              />
            )}
          </li>
        );
      }}
      onChange={(_, newValue) => {
        setSelected(newValue);
        onFolderSelected(newValue);
      }}
    />
  );
}

export const AutocompleteFolder = withLoggedInState(
  AutocompleteFolderWithState
);
