import { WithRef } from "@converge-collective/common/models/Base";
import { Folder } from "@converge-collective/common/models/Folder";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { useFirestoreCollectionData } from "reactfire";
import {
  allNetworkFoldersQuery,
  flattenFolders,
  FolderNodeInfo,
} from "~/lib/folder";
import { useLoggedInState } from "~/lib/useLoggedInState";

/**
 * Find all folders in a network that a user has a minimum of `read` access to
 */
export function useFolders({
  network,
  profile,
}: {
  network: WithRef<Network>;
  profile: WithRef<Profile>;
}): { folders: WithRef<Folder>[]; isLoading: boolean } {
  // console.log("fetching folders", { network, profile });
  // if the user is a network admin, they can see all folders
  const { isNetworkAdmin } = useLoggedInState();
  const foldersQuery = allNetworkFoldersQuery(
    network,
    profile,
    isNetworkAdmin === true
  );

  // NOTE: allNetworkFoldersQuery takes into account authz permissions
  // but they aren't enforced by firestore security rules
  // TODO - reflect these in firestore security rules to actually prevent
  // someone from reading these folders (and the docs in them)
  const { data: folders = [], status } =
    useFirestoreCollectionData(foldersQuery);
  const isLoading = status === "loading";
  return { folders, isLoading };
}

/**
 * Find a single folder by its slug
 *
 * Because we have subfolders, we can't look up the top level folder by its slug
 * anymore since the slug passed in might refer to a subfolder instead.
 * Therefore, get all folders, flatten them, then search for the slug.
 *
 *  TODO take into account authz
 */
export function useFolder({
  network,
  profile,
  slug,
}: {
  network: WithRef<Network>;
  profile: WithRef<Profile>;
  slug: string;
}): FolderNodeInfo | undefined {
  const { folders } = useFolders({ network, profile });
  if (folders.length === 0) {
    return undefined;
  }

  const flattened = flattenFolders(folders);
  const folder = flattened.find((f) => f.slug === slug);
  return folder;
}
