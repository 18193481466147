export const backgroundColors = [
  "#ef476f",
  "#f78c6b",
  "#ffd166",
  "#06d6a0",
  "#118ab2",
  "#073b4c",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

export const defaultColor = backgroundColors[0];

export const lightBrightColorsForBackground: string[] = [
  "#FFDAB9", // Pale Orange
  "#AFEEEE", // Pale Turquoise
  "#98FB98", // Pale Green
  "#DDA0DD", // Plum
  "#E6E6FA", // Lavender
  "#F0E68C", // Khaki
  "#FFB6C1", // Light Pink
  "#00FA9A", // Medium Spring Green
  "#FFE4B5", // Tea Rose
  "#7FFFD4", // Pengrunmenth
  "#FF6347", // Tomato
  "#FFA07A", // Miniature
  "#FAFAD2", // Linen
  "#87CEEB", // Sky Blue
];
