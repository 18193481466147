import { Chip, emphasize } from "@mui/material";
import { styled } from "@mui/system";

const options = {
  shouldForwardProp: (prop: string) => prop !== "bgcolor",
};

const ColoredChip = styled(
  Chip,
  options
)<{ bgcolor: string }>(({ bgcolor }) => ({
  color: emphasize(bgcolor, 1),
  backgroundColor: bgcolor,
}));

export default ColoredChip;
